import { Flex, Button } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import * as API from "@lib/hooks/api";
import { SiweMessage } from "siwe";
import { ethers } from "ethers";
import { useRouter } from "next/router";
import { useUserContext } from "@lib/hooks/user";
import Link from "next/link";
import { signInWithEthereum } from "@lib/hooks/web3";

export default function Login() {
  const { user, isLoggedIn } = useUserContext();

  const router = useRouter();

  const [message, setMessage] = useState<null | string>(null);

  if (isLoggedIn) {
    return (
      <Flex alignItems={"center"} direction={"column"}>
        <br />
        <div> Logged in! </div>
        <Button variant="link" padding={2} onClick={() => router.push("/")}>
          Go home
        </Button>

        <br />
        <div> User Address : {user.primaryWallet} </div>
        <div> User nonce : {user.nonce} </div>
      </Flex>
    );
  } else {
    return (
      <Flex alignItems={"center"} direction={"column"}>
        {/* <Button> Connect Wallet </Button> */}
        <br />
        <div>You are not logged in</div>
        <div>{message ?? ""}</div>
        <br />
        <Button
          onClick={() =>
            signInWithEthereum().then(
              () => {
                router.reload();
              },
              (error) => setMessage(error.message)
            )
          }
        >
          Log in
        </Button>
      </Flex>
    );
  }
}
